import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const Advocate = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>Advocate</h1>
          <h2>Introduction</h2>
          <p>
            A website that was a big stylistic challenge. The client wanted a
            page in the colors of black, brown and gold. The website was
            supposed to strongly refer to the current appearance of the law
            firm. I didn't fully design the whole thing myself because I
            received it 30% complete. Unfortunately, the contrast was not
            preserved there, but the project has already been accepted by the
            client. I tried to get out of it as best as possible, which resulted
            in quite unconventional solutions.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/advocat.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_advocat.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default Advocate;

export function Head() {
  return <title>Advocate</title>;
}
